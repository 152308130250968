import React from 'react'
import { formatDate } from 'src/commonFunctions'
import OfficialTag from 'components/atoms/OfficialTag'

export default ({ date, isOfficial, genre }) => (
  <div
    className={`card_header_half ${isOfficial && 'card_header_half_official'}`}
  >
    <div className="container">
      <div className="icon">
        <img lazyload src={genre.IMAGE} />
      </div>
      <div className="genre_title">{genre.TITLE}</div>
      {isOfficial ? (
        <OfficialTag />
      ) : (
        <div className="date">{formatDate(date)}</div>
      )}
    </div>
  </div>
)
