import React from 'react'
import ListCard from 'components/molecules/ListCard'
import ShowMoreCard from 'components/molecules/ShowMoreCard'

import {
  GROUPED_ARTICLE_NUMBER,
  NEW_POST_PAGE_ARTICLE_NUMBER,
} from 'src/constants'
import { stripOfficial } from 'src/commonFunctions'
import { compareDate, ARTICLE_TYPE } from 'src/statics'

// 3記事だけ表示する
const createArticlesListIncludeBanner = (
  articles,
  category,
  isArticlesPage,
  isHome
) => {
  const groupedArticleNumber = isHome
    ? GROUPED_ARTICLE_NUMBER
    : NEW_POST_PAGE_ARTICLE_NUMBER
  const groupedArticles =
    !category || category.includes(ARTICLE_TYPE.NEWENTRY)
      ? articles
          .sort(compareDate)
          .map((node, index) => {
            if (index < groupedArticleNumber) {
              return node
            }
          })
          // undefinedが入ってしまうのでfilter
          .filter(node => node)
      : articles
          .filter(node => category.includes(node.type))
          .map((node, index) => {
            // 各カテゴリページの時は全件出す
            if (isArticlesPage) return node

            if (index < GROUPED_ARTICLE_NUMBER) {
              return node
            }
          })
          // undefinedが入ってしまうのでfilter
          .filter(node => node)
  return groupedArticles
}

export default class ArticleList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const isHome = this.props.title === 'HOME'
    const articlesListIncludeBanner = createArticlesListIncludeBanner(
      this.props.articles,
      this.props.articles_type,
      this.props.is_articles_page,
      isHome
    )

    return (
      <div className={`list_card_flex ${isHome ? '' : 'list_area'}`}>
        <div className={`list_card ${isHome ? '' : 'list_grid'}`}>
          {articlesListIncludeBanner.map(node => (
            <ListCard
              type={node.type}
              key={node.id}
              slug={node.slug}
              title={node.title}
              date={node.date}
              isOfficial={stripOfficial(node.isOfficial)}
              ogp={node.ogpSettings.ogp}
              linkToIndex={node.linkToIndex}
              isFull
            />
          ))}
          {isHome && (
            <ShowMoreCard
              slug={this.props.show_more_link}
              background={this.props.className}
            />
          )}
        </div>
      </div>
    )
  }
}
