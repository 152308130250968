import React from 'react'
import { Link } from 'gatsby'
import { getImageUrl, getAltText } from 'src/commonFunctions'
import { formatDate } from 'src/commonFunctions'

export default ({
  type,
  slug,
  title,
  date,
  isOfficial,
  ogp,
  linkToIndex,
  isFull,
}) => (
  <Link
    to={`/${type}/${slug}`}
    className={`${isFull ? 'full_card' : 'half_card'} slider_content`}
  >
    <div className="content_info">
      <div className="top_image_div">
        <img lazyload="true" src={getImageUrl(ogp)} alt={getAltText(ogp)} />
      </div>
      <div className="card_text_area">
        <h2 className="title">{title}</h2>
        {linkToIndex && linkToIndex.linktoindex ? (
          <h3 className="series">{linkToIndex.linktoindex.title}</h3>
        ) : (
          <h3 className="series" />
        )}

        <div className="date">{formatDate(date)}</div>
      </div>
    </div>
  </Link>
)
