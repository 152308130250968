import React from 'react'
import Firework from 'components/atoms/Firework'

export default ({ firework }) => (
  <>
    {[...Array(80).keys()].map(index => (
      <Firework key={index} firework={firework} className="muso_huin" />
    ))}
  </>
)
