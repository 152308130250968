import React from 'react'
import { Link } from 'gatsby'
import { intl } from 'src/constants'

export default ({ slug, background }) => (
  <Link to={`/${slug}`} className="full_card">
    <div className={`show_more_content ${background}`}>
      <div className="show_more_color">
        <div className="show_more">{intl.getMessage('expand')}</div>
      </div>
    </div>
  </Link>
)
