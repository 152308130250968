import React from 'react'
import Helmet from 'react-helmet'
import uuid from 'uuid/v4'
import { SITE_TITLE } from 'src/constants'
import Ogp from 'components/atoms/Ogp'
import SeriesCarousel from 'components/SeriesCarousel'
import SliderList from 'components/SliderList'
import Banner from 'components/atoms/Banner'
import ShowMoreArticleList from 'components/ShowMoreArticleList'
import HeaderPosts from 'components/HeaderPosts'
import Footer from 'components/Footer'

import { ARTICLE_TYPE } from 'src/statics'
import PopupManager from 'components/PopupManager'
import { isDateAfterToday, isDateBeforeToday } from 'src/commonFunctions'

import aya from '../../resource/aya.png'
import hatate from '../../resource/hatate.png'
import kosuzu from '../../resource/kosuzu.png'
import aq from '../../resource/aq.png'
import sumireko from '../../resource/sumireko.png'
import yukari from '../../resource/yukari.png'
import creation from '../../resource/creation.png'
import newEntry from '../../resource/newEntry.png'
import feature from '../../resource/feature.png'
import topic from '../../resource/topic.png'
import news from '../../resource/news.png'
import creationCn from '../../resource/creation_cn.png'
import newEntryCn from '../../resource/newEntry_cn.png'
import featureCn from '../../resource/feature_cn.png'
import topicCn from '../../resource/topic_cn.png'
import newsCn from '../../resource/news_cn.png'
import creationKo from '../../resource/creation_ko.png'
import newEntryKo from '../../resource/newEntry_ko.png'
import featureKo from '../../resource/feature_ko.png'
import topicKo from '../../resource/topic_ko.png'
import newsKo from '../../resource/news_ko.png'

export default ({ pageContext }) => {
  // 広告は1つの投稿ですべてを設定するので[0]使用
  const { adbanner } = pageContext.adbanners.nodes[0]
  // 通常バナーは複数設定可能なのでリストのまま
  const displayBanners = adbanner.banners.filter(
    banner => isDateAfterToday(banner.start) && isDateBeforeToday(banner.end)
  )
  const currentBanner = displayBanners[0]
  const secondBanner =
    displayBanners.length >= 2 ? displayBanners[1] : displayBanners[0]
  const thirdBanner =
    displayBanners.length >= 3 ? displayBanners[2] : displayBanners[0]
  const lastBanner = displayBanners.slice(-1)[0]

  return (
    <div>
      <Helmet title={`${pageContext.pageMeta.TITLE} | ${SITE_TITLE}`} />
      <Ogp
        title={pageContext.pageMeta.TITLE}
        postUrl={pageContext.pageMeta.POST_URL}
      />
      {
        // 祭り用バナーの表示
        // 5件の表示
      }
      <HeaderPosts articles={pageContext.articles} />
      <SeriesCarousel articles={pageContext.articles} adbanner={adbanner} />
      <div className="creation">
        <div className="row">
          <div className="centered">
            {process.env.LANGUAGE_ALIAS === 'cn' ? (
              <img lazyload src={creationCn} />
            ) : process.env.LANGUAGE_ALIAS === 'ko' ? (
              <img lazyload src={creationKo} />
            ) : (
              <img lazyload src={creation} />
            )}
          </div>
          <div className="content_img">
            <img lazyload src={aya} />
          </div>
        </div>
        <SliderList
          title={pageContext.pageMeta.TITLE}
          className="creation"
          articles={pageContext.articles}
          adbanners={pageContext.adbanners}
          articles_type={[ARTICLE_TYPE.NOVEL, ARTICLE_TYPE.COMIC]}
          show_more_link="/creation"
        />
      </div>
      <div className="white_space" />
      <div className="new_entry">
        <div className="row">
          <div className="hatate_img">
            <img lazyload src={hatate} />
          </div>
          <div className="entry_img">
            {process.env.LANGUAGE_ALIAS === 'cn' ? (
              <img lazyload src={newEntryCn} />
            ) : process.env.LANGUAGE_ALIAS === 'ko' ? (
              <img lazyload src={newEntryKo} />
            ) : (
              <img lazyload src={newEntry} />
            )}
          </div>
        </div>

        <ShowMoreArticleList
          title={pageContext.pageMeta.TITLE}
          className="new_entry"
          articles={pageContext.articles}
          adbanners={pageContext.adbanners}
          articles_type={[ARTICLE_TYPE.NEWENTRY]}
          show_more_link="/new_post"
        />
      </div>
      <div className="white_space_feature">
        {currentBanner && (
          <Banner
            name={currentBanner.name}
            link={currentBanner.link}
            image={currentBanner.image}
          />
        )}
      </div>
      <div className="feature">
        <div className="row">
          <div className="feature_img">
            {process.env.LANGUAGE_ALIAS === 'cn' ? (
              <img lazyload src={featureCn} />
            ) : process.env.LANGUAGE_ALIAS === 'ko' ? (
              <img lazyload src={featureKo} />
            ) : (
              <img lazyload src={feature} />
            )}
          </div>
          <div className="kosuzu_img">
            <img lazyload src={kosuzu} />
          </div>
        </div>
        <SliderList
          title={pageContext.pageMeta.TITLE}
          className="feature"
          articles={pageContext.articles}
          adbanners={pageContext.adbanners}
          articles_type={[
            ARTICLE_TYPE.INDEXINTERVIEW,
            ARTICLE_TYPE.INDEXCOMIC,
            ARTICLE_TYPE.INDEXNOVEL,
          ]}
          show_more_link="/feature"
          custom_css="article_feature"
        />
      </div>
      <div className="white_space_topic">
        {secondBanner && (
          <Banner
            name={secondBanner.name}
            link={secondBanner.link}
            image={secondBanner.image}
          />
        )}
      </div>
      <div className="topic">
        <div className="row">
          <div className="aq_img">
            <img lazyload src={aq} />
          </div>
          <div className="topic_img">
            {process.env.LANGUAGE_ALIAS === 'cn' ? (
              <img lazyload src={topicCn} />
            ) : process.env.LANGUAGE_ALIAS === 'ko' ? (
              <img lazyload src={topicKo} />
            ) : (
              <img lazyload src={topic} />
            )}
          </div>
        </div>
        <div className="tag_area">
          <div className="right_dummy" />
          <div className="tag">
            <div className="top_dummy" />
            <div className="tag_link_area">
              {pageContext.tags.map((node, index) => {
                const linkhref = `/tags/${node.slug}`
                if (index < 16) {
                  return (
                    <a href={linkhref} className="tag_link" key={uuid()}>
                      #{node.name}
                    </a>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="white_space">
        {thirdBanner && (
          <Banner
            name={thirdBanner.name}
            link={thirdBanner.link}
            image={thirdBanner.image}
          />
        )}
      </div>
      <div className="news">
        <div className="row">
          <div className="news_img">
            {process.env.LANGUAGE_ALIAS === 'cn' ? (
              <img lazyload src={newsCn} />
            ) : process.env.LANGUAGE_ALIAS === 'ko' ? (
              <img lazyload src={newsKo} />
            ) : (
              <img lazyload src={news} />
            )}
          </div>
          <div className="sumireko_img">
            <img lazyload src={sumireko} />
          </div>
        </div>
        <ShowMoreArticleList
          title={pageContext.pageMeta.TITLE}
          className="news"
          articles={pageContext.articles}
          adbanners={pageContext.adbanners}
          articles_type={[ARTICLE_TYPE.NEWS]}
          show_more_link="/news"
        />
      </div>

      {lastBanner && (
        <Banner
          name={lastBanner.name}
          link={lastBanner.link}
          image={lastBanner.image}
        />
      )}
      <div className="yukari_img">
        <img lazyload src={yukari} />
      </div>
      <PopupManager />
      <Footer />
    </div>
  )
}
