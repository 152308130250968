import React from 'react'
import { formatDate } from 'src/commonFunctions'
import OfficialTag from 'components/atoms/OfficialTag'

export default ({ date, isOfficial, genre }) => (
  <div className={`card_header ${isOfficial && 'card_header_official'}`}>
    <div className="icon">
      <img src={genre.IMAGE} />
    </div>
    <div className="genre_title">{genre.TITLE}</div>
    <div className="date">{formatDate(date)}</div>
    {isOfficial && <OfficialTag />}
  </div>
)
