import React from 'react'
import { Link } from 'gatsby'
import {
  getImageUrl,
  getAltText,
  convertArticleType2menus,
} from 'src/commonFunctions'
import CardHeader from 'components/molecules/CardHeader'
import CardHeaderHalf from 'components/molecules/CardHeaderHalf'

export default ({
  type,
  slug,
  title,
  date,
  isOfficial,
  ogp,
  linkToIndex,
  isFull,
}) => (
  <Link
    to={`/${type}/${slug}`}
    className={`${isFull ? 'full_card' : 'half_card'} ${
      isOfficial ? 'official' : 'un_official'
    }`}
  >
    {isFull ? (
      <CardHeader
        date={date}
        isOfficial={isOfficial}
        genre={convertArticleType2menus(type)}
        isFull
      />
    ) : (
      <CardHeaderHalf
        date={date}
        isOfficial={isOfficial}
        genre={convertArticleType2menus(type)}
        isFull
      />
    )}
    <div className="content_info">
      <div className="top_image">
        <img lazyload src={getImageUrl(ogp)} alt={getAltText(ogp)} />
      </div>
      {linkToIndex && linkToIndex.linktoindex ? (
        <h3 className="series">{linkToIndex.linktoindex.title}</h3>
      ) : null}
      <h2 className="title">{title}</h2>
    </div>
  </Link>
)
