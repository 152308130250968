import React from 'react'
import ReactGA from 'react-ga'

export default ({ fullSizeBackground, closeFullSizeBackground }) => (
  <div className="advertisement_overlay">
    <div className="overlay_inner" onClick={closeFullSizeBackground}>
      <button className="close_button" onClick={closeFullSizeBackground}>
        ✕
      </button>
      <a
        href={fullSizeBackground.link}
        target="_blank"
        rel="noopener"
        onClick={() =>
          ReactGA.event({
            category: fullSizeBackground.link,
            action: 'click full size banner',
            value: 1,
          })
        }
      >
        <img
          src={fullSizeBackground.image.sourceUrl}
          alt={fullSizeBackground.name}
        />
      </a>
    </div>
  </div>
)
