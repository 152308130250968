import React from 'react'
import { Link } from 'gatsby'
import { isUnderConstruction, createInnerLink } from 'src/commonFunctions'

export default ({ content }) => {
  const isdisabled = isUnderConstruction(content.SLUG)
  const titleLine = content.TITLE.split('<br />')
  const menuContent = (
    <div className={`menu_icon ${isdisabled ? 'gray_out' : ''}`}>
      <div>
        <img src={content.IMAGE} />
      </div>
      <p className="menu_title">{titleLine[0]}</p>
      <p className="menu_title">{titleLine[1]}</p>
    </div>
  )
  if (isdisabled) return menuContent
  return <Link to={createInnerLink(content.TO)}>{menuContent}</Link>
}
