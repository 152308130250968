import React from 'react'
import SliderCard from 'components/molecules/SliderCard'
import { stripOfficial } from 'src/commonFunctions'
import Slider from 'react-slick'
import { compareDate } from 'src/statics'

const createArticlesListIncludeBanner = articles => {
  const groupedArticleNumber = 5
  const groupedArticles = articles
    .sort(compareDate)
    .map((node, index) => {
      if (index < groupedArticleNumber) {
        return node
      }
    })
    // undefinedが入ってしまうのでfilter
    .filter(node => node)

  return groupedArticles
}

// トップに表示する5記事を取得する
// 投稿タイプ「広告」の固定記事に設定されており、表示順の数字が若いものが優先
const getFirstFive = (adbanner, articles) => {
  const statics = adbanner.statics || []
  const sortedArticles = articles.sort((a, b) => {
    const aSearchString = `${a.type}/${a.slug}`
    const bSearchString = `${b.type}/${b.slug}`
    const aResult = statics.find(
      staticArticle => staticArticle.link.url.indexOf(aSearchString) !== -1
    )
    const bResult = statics.find(
      staticArticle => staticArticle.link.url.indexOf(bSearchString) !== -1
    )
    if (aResult && bResult) {
      return aResult.order - bResult.order
    } else if (aResult) {
      return -1
    } else if (bResult) {
      return 1
    } else {
      return 0
    }
  })
  return sortedArticles.slice(0, 5)
}

export default class SeriesCarousel extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const displayArticles = createArticlesListIncludeBanner(this.props.articles)
    const staticArticle = this.props.adbanner.statics
      ? getFirstFive(this.props.adbanner, this.props.articles)
      : []
    const settings = {
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    }

    return (
      <Slider {...settings} className="slider_height slider_opacity">
        {staticArticle.map(article => (
          <SliderCard
            type={article.type}
            key={article.id}
            slug={article.slug}
            title={article.title}
            date={article.date}
            isOfficial={stripOfficial(article.isOfficial)}
            ogp={article.ogpSettings.ogp}
            linkToIndex={article.linkToIndex}
            isFull
          />
        ))}
        {displayArticles.map(article => (
          <SliderCard
            type={article.type}
            key={article.id}
            slug={article.slug}
            title={article.title}
            date={article.date}
            isOfficial={stripOfficial(article.isOfficial)}
            ogp={article.ogpSettings.ogp}
            linkToIndex={article.linkToIndex}
            isFull
          />
        ))}
      </Slider>
    )
  }
}
