import React from 'react'
import ListCard from 'components/molecules/ListCard'
import ShowMoreCard from 'components/molecules/ShowMoreCard'
import { Carousel } from 'components/molecules/Carousel'

import { CAROUSEL_ARTICLE_NUMBER } from 'src/constants'
import { stripOfficial } from 'src/commonFunctions'

// 3記事だけ表示する
const createArticlesListIncludeBanner = (articles, category) => {
  const articleNumber = CAROUSEL_ARTICLE_NUMBER
  const groupedArticles = articles
    .filter(node => {
      return category.includes(node.type)
    })
    .map((node, index) => {
      if (index < articleNumber) {
        return node
      }
    })
    // undefinedが入ってしまうのでfilter
    .filter(node => node)
  return groupedArticles
}

export default class ArticleList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const articlesListIncludeBanner = createArticlesListIncludeBanner(
      this.props.articles,
      this.props.articles_type
    )

    const slides = articlesListIncludeBanner.map(node => (
      <ListCard
        type={node.type}
        key={node.id}
        slug={node.slug}
        title={node.title}
        date={node.date}
        isOfficial={stripOfficial(node.isOfficial)}
        ogp={node.ogpSettings.ogp}
        linkToIndex={node.linkToIndex}
        isFull
      />
    ))

    slides.push(
      <ShowMoreCard
        slug={this.props.show_more_link}
        background={this.props.className}
      />
    )

    return (
      <div className={`article_slider_area ${this.props.custom_css}`}>
        <Carousel
          slides={slides}
          autoplay={true}
          className="slider_height article_slider"
        />
      </div>
    )
  }
}
