import React from 'react'
import ReactGA from 'react-ga'
import { Query } from 'react-apollo'
import { FESTIVAL_AND_STATICS_QUERY } from 'src/queries'
import { isDateAfterToday, isDateBeforeToday } from 'src/commonFunctions'

const retrieveFestivalBanner = adbanner => {
  const festivalBanners = adbanner.festivalBanner || []
  // 祭り広告は期間を満たしたもののうち先頭1件だけ有効
  return festivalBanners.filter(
    banner => isDateAfterToday(banner.start) && isDateBeforeToday(banner.end)
  )[0]
}

// トップに表示する5記事を取得する
// 投稿タイプ「広告」の固定記事に設定されており、表示順の数字が若いものが優先
const getFirstFive = (adbanner, articles) => {
  const statics = adbanner.statics || []
  const sortedArticles = articles.sort((a, b) => {
    const aSearchString = `${a.type}/${a.slug}`
    const bSearchString = `${b.type}/${b.slug}`
    const aResult = statics.find(
      staticArticle => staticArticle.link.url.indexOf(aSearchString) !== -1
    )
    const bResult = statics.find(
      staticArticle => staticArticle.link.url.indexOf(bSearchString) !== -1
    )
    if (aResult && bResult) {
      return aResult.order - bResult.order
    } else if (aResult) {
      return -1
    } else if (bResult) {
      return 1
    } else {
      return 0
    }
  })
  return sortedArticles.slice(0, 5)
}

export default class HeaderPosts extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      // 祭り広告を使うのでクエリを投げる
      <Query query={FESTIVAL_AND_STATICS_QUERY}>
        {({ data, loading }) => {
          if (loading) return null
          if (!data) return null

          // 広告は1つの投稿ですべてを設定するので[0]使用
          const adbanner = data.adbanners.nodes[0].adbanner
          const festivalBanner = retrieveFestivalBanner(adbanner)
          const firstFive = getFirstFive(adbanner, this.props.articles.slice())
          if (festivalBanner) {
            // 祭り広告があるので4件しか表示できない。5件目を捨てる
            firstFive.pop()
            return (
              <div className="top_card">
                <div
                  className="festival_banner"
                  onClick={() =>
                    ReactGA.event({
                      category: festivalBanner.link,
                      action: 'click festival banner',
                      value: 1,
                    })
                  }
                >
                  <a href={festivalBanner.link} target="_blank" rel="noopener">
                    <img
                      lazyload
                      src={festivalBanner.image.sourceUrl}
                      alt={festivalBanner.name}
                    />
                  </a>
                </div>
              </div>
            )
          } else {
            return null
          }
        }}
      </Query>
    )
  }
}
