import React from 'react'
import { MENUS, intl, LANGUAGE_ALIAS } from 'src/constants'
import MenuIcon from 'components/atoms/MenuIconForTop'
import LanguageSelection from 'components/LanguageSelection'
import Search from './GoogleCustomSearch'
import ClientOnly from './hooks/ClientOnly'

export default () => (
  <>
    <div className="menu_box">
      <MenuIcon content={MENUS.INTERVIEWS} />
      <MenuIcon content={MENUS.REPORTS} />
      <MenuIcon content={MENUS.COLUMNS} />
      <MenuIcon content={MENUS.FANZINE_REVIEW} />
      <MenuIcon content={MENUS.MUSIC_REVIEW} />
      <MenuIcon content={MENUS.GAME_REVIEW} />
      <MenuIcon content={MENUS.WHERE} />
      <MenuIcon content={MENUS.EASTERNPLAYING} />
    </div>
    <div className="search_area">
      <div className="lang_selection">
        <LanguageSelection />
      </div>
      <ClientOnly className="search">
        <Search lang={LANGUAGE_ALIAS} />
      </ClientOnly>
    </div>
    <div className="sns">
      <p>公式SNSはこちら</p>
      <div className="twitter">
        <a
          href="https://twitter.com/touhou_station?ref_src=twsrc%5Etfw"
          className="twitter-follow-button"
          data-show-count="false"
          data-lang="ja"
        >
          Follow @touhou_station
        </a>
      </div>
      {LANGUAGE_ALIAS === 'en' && (
        <div className="twitter twitter_en">
          <a
            href="https://twitter.com/Garakuta_EN?ref_src=twsrc%5Etfw"
            className="twitter-follow-button"
            data-show-count="false"
            data-lang="en"
          >
            Follow @Garakuta_EN
          </a>
        </div>
      )}
    </div>
  </>
)
