import React from 'react'
import ReactGA from 'react-ga'

export default ({ firework, className }) => (
  <div
    className={className}
    onClick={() =>
      ReactGA.event({
        category: firework.link,
        action: 'click firework',
        value: 1,
      })
    }
  >
    <a href={firework.link} target="_blank" rel="noopener">
      <img
        lazyload
        src={firework.image.sourceUrl}
        alt={firework.image.altText}
      />
    </a>
  </div>
)
