import React from 'react'
import { Link } from 'gatsby'
import {
  SITE_TITLE,
  ZUN_TWITTER,
  ORIGINAL_HP,
  ZUN_BLOG,
  TOUHOU_NEWS,
  LANGUAGE_ALIAS,
  MAIL_FORM,
  CONTACT_PRESS,
} from 'src/constants'
import MenuBox from 'components/MenuBox'
import { intl } from 'src/constants'
import { myContext } from 'layouts/BasicLayout'

import logo from '../../resource/footer_logo.png'

export default ({ displayMenu }) => (
  <div className="footer">
    {!displayMenu && <MenuBox />}
    <div className="footer_text">
      <Link to="/">
        <img src={logo} alt={SITE_TITLE} className="logo"></img>
      </Link>
      <div className="info">
        <div className="footer_text_div">
          <p className="original">
            <a href={ORIGINAL_HP} target="_brank" rel="nofollow">
              {intl.getMessage('original_hp')}
            </a>
          </p>
          <p className="original">
            <a href={ZUN_BLOG} target="_brank" rel="nofollow">
              {intl.getMessage('zun_blog')}
            </a>
          </p>
          <p className="original">
            <a href={ZUN_TWITTER} target="_brank" rel="nofollow">
              {intl.getMessage('zun_hp')}
            </a>
          </p>
          <p className="original">
            <a href={TOUHOU_NEWS} target="_brank" rel="nofollow">
              {intl.getMessage('touhou_news')}
            </a>
          </p>
        </div>
        <div className="footer_text_div">
          <p className="original">
            Twitter
            <a
              href="https://twitter.com/touhou_station"
              target="_brank"
              rel="nofollow"
            >
              {' '}
              <span className="link_line"> @touhou_station</span>
            </a>
          </p>
          <p className="original">
            {LANGUAGE_ALIAS === 'ja'
              ? 'メールフォームは'
              : LANGUAGE_ALIAS === 'en'
              ? 'Mail: en.garakuta.soushi@gmail.com'
              : 'Mail: touhou.garakuta@gmail.com'}
            {LANGUAGE_ALIAS === 'ja' && (
              <a href={MAIL_FORM} target="_brank" rel="nofollow">
                <span className="link_line">{intl.getMessage('here')}</span>
              </a>
            )}
          </p>
          {LANGUAGE_ALIAS === 'ja' && (
            <p className="original">
              「記事にしてほしい！」という連絡は
              {LANGUAGE_ALIAS === 'ja' && (
                <a href={CONTACT_PRESS} target="_brank" rel="nofollow">
                  <span className="link_line">{intl.getMessage('here')}</span>
                </a>
              )}
            </p>
          )}
        </div>
      </div>
      <div className="shihou_twitter">
        <myContext.Consumer>
          {context => (
            <p className="show_modal" onClick={() => context.modalOpen()}>
              <span>{intl.getMessage('illustrations')} shihou</span>
            </p>
          )}
        </myContext.Consumer>
        <a href="https://twitter.com/shihou_1" className="shihou_twitter_link">
          <span className="link_line">@shihou_1</span>
        </a>
        <br />
        <br />
        Copyright 2021 東方我楽多叢誌
      </div>
    </div>
  </div>
)
