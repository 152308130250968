import React from 'react'
import graphqlClient from 'src/graphqlClient'
import { FIREWORK_BANNER_QUERY } from 'src/queries'
import { isDateAfterToday, isDateBeforeToday } from 'src/commonFunctions'
import AdvertisementOverlay from 'components/AdvertisementOverlay'
import MusoHuin from 'components/fireworks/MusoHuin'

export default class PopupManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      firework: null,
      fullSizeBackground: null,
      showFirework: false,
      showFullSizeBackground: true,
    }
  }

  async componentDidMount() {
    const { data } = await graphqlClient.query({
      query: FIREWORK_BANNER_QUERY,
    })

    // 広告は1つの投稿ですべてを設定するので[0]使用
    const adbanner = data.adbanners.nodes[0].adbanner

    const fullSizeBackgrounds = adbanner.fullsizeBackground || []
    const fireworkBanners = adbanner.firework || []

    // 全面広告は期間を満たしたもののうち先頭1件だけ有効
    const fullSizeBackground = fullSizeBackgrounds.filter(
      banner => isDateAfterToday(banner.start) && isDateBeforeToday(banner.end)
    )[0]
    // 弾幕広告は期間を満たしたもののうち先頭1件だけ有効
    const firework = fireworkBanners.filter(
      banner => isDateAfterToday(banner.start) && isDateBeforeToday(banner.end)
    )[0]

    this.setState({
      firework: firework,
      fullSizeBackground: fullSizeBackground,
    })

    if (!fullSizeBackground) {
      setTimeout(this.startFirework, 5000)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.showFullSizeBackground !== this.state.showFullSizeBackground &&
      !this.state.showFullSizeBackground
    ) {
      setTimeout(this.startFirework, 5000)
    }
  }

  startFirework = () => {
    this.setState({ showFirework: true })
    setTimeout(() => this.setState({ showFirework: false }), 20000)
  }

  closeFullSizeBackground() {
    this.setState({
      showFullSizeBackground: false,
    })
    document.body.classList.remove('fixed')
  }

  render() {
    if (this.state.fullSizeBackground && this.state.showFullSizeBackground) {
      document.body.classList.add('fixed')
      return (
        <AdvertisementOverlay
          fullSizeBackground={this.state.fullSizeBackground}
          closeFullSizeBackground={() => this.closeFullSizeBackground()}
        />
      )
    }
    if (this.state.firework && this.state.showFirework) {
      return <MusoHuin firework={this.state.firework} />
    }
    return null
  }
}
